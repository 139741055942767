"use strict";

var _interopRequireDefault = require("/var/jenkins_home/workspace/uat-ui-web-integration/node_modules/_@babel_runtime@7.25.6@@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.constantRoutes = exports.asyncRoutes = void 0;
var _toConsumableArray2 = _interopRequireDefault(require("/var/jenkins_home/workspace/uat-ui-web-integration/node_modules/_@babel_runtime@7.25.6@@babel/runtime/helpers/toConsumableArray.js"));
var _interopRequireWildcard2 = _interopRequireDefault(require("/var/jenkins_home/workspace/uat-ui-web-integration/node_modules/_@babel_runtime@7.25.6@@babel/runtime/helpers/interopRequireWildcard.js"));
require("core-js/modules/es.array.concat.js");
require("core-js/modules/es.function.name.js");
require("core-js/modules/es.object.to-string.js");
var _vue = _interopRequireDefault(require("vue"));
var _vueRouter = _interopRequireDefault(require("vue-router"));
var _layout = _interopRequireDefault(require("@/layout"));
var _subapp = _interopRequireDefault(require("./modules/subapp"));
_vue.default.use(_vueRouter.default);
var head = document.getElementsByTagName('head');
var meta = document.createElement('meta');
meta.name = 'referrer';
//根据实际情况修改referrer的值，可选值参考上文
meta.content = 'no-referrer-when-downgrade';
head[0].appendChild(meta);
var constantRoutes = exports.constantRoutes = [{
  path: '/redirect',
  component: _layout.default,
  hidden: true,
  children: [{
    path: '/redirect/:path*',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/redirect/index'));
      });
    }
  }]
}, {
  path: '/login',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/login/index'));
    });
  },
  // import ('@/views/login/login'),
  hidden: true
}, {
  path: '/auth-redirect',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/login/auth-redirect'));
    });
  },
  hidden: true
}, {
  path: '/404',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/error-page/404'));
    });
  },
  hidden: true
}, {
  path: '/401',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/error-page/401'));
    });
  },
  hidden: true
}, {
  path: '/register',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/login/register'));
    });
  },
  hidden: true
}, {
  path: '/ssoLogin',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/login/ssoLogin'));
    });
  },
  hidden: true
}, {
  path: '/signedPage',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/electronicContractPlatform/signedPage'));
    });
  },
  hidden: true
}, {
  path: '/collectConsignmentInfo',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/NonAutoInsurance/consignmentAgreement/collectConsignmentInfo'));
    });
  },
  hidden: true
}, {
  path: '/jumpPage',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/NonAutoInsurance/consignmentAgreement/jumpPage'));
    });
  },
  hidden: true
}, {
  path: '/electronicContractPlatform/sign',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/NonAutoInsurance/consignmentAgreement/sign'));
    });
  },
  hidden: true
}, {
  path: '/signConfirm',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/NonAutoInsurance/consignmentAgreement/newSignConfirm'));
    });
  },
  hidden: true
},
// {
//   path: '/newSignConfirm',
//   component: () =>
//       import('@/views/NonAutoInsurance/consignmentAgreement/newSignConfirm'),
//   hidden: true
// }, 
{
  path: '/electronicContractPlatform/signatureBoard',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/NonAutoInsurance/consignmentAgreement/signatureBoard'));
    });
  },
  hidden: true
}, {
  path: '/electronicContractPlatform/preview',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/NonAutoInsurance/consignmentAgreement/preview'));
    });
  },
  hidden: true
}, {
  path: '/electronicContractPlatform/download',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/NonAutoInsurance/consignmentAgreement/download'));
    });
  },
  hidden: true
}, {
  path: '/servicePack/informationCheck',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/servicePackManage/informationCheck'));
    });
  },
  hidden: true
}, {
  path: '/',
  component: _layout.default,
  redirect: '/login'
}, {
  path: '/dashboard',
  component: _layout.default,
  children: [{
    path: '/dashboard',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/dashboard/index'));
      });
    },
    name: 'Dashboard',
    meta: {
      title: '首页',
      icon: 'dashboard'
    },
    hidden: true
  }]
}, {
  path: '/profile',
  component: _layout.default,
  redirect: '/profile/index',
  hidden: true,
  children: [{
    path: '/profile/index',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/profile/index'));
      });
    },
    name: 'Profile',
    meta: {
      title: 'Profile',
      icon: 'user',
      noCache: true
    }
  }]
}];
var _default = exports.default = [].concat(constantRoutes, (0, _toConsumableArray2.default)(_subapp.default));
var asyncRoutes = exports.asyncRoutes = (0, _toConsumableArray2.default)(_subapp.default);