"use strict";

var _interopRequireDefault = require("/var/jenkins_home/workspace/uat-ui-web-integration/node_modules/_@babel_runtime@7.25.6@@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.object.keys.js");
require("core-js/modules/es.regexp.exec.js");
require("core-js/modules/es.regexp.test.js");
var _rules = _interopRequireDefault(require("@/utils/rules"));
var _axios = _interopRequireDefault(require("axios"));
var _common = require("@/utils/common");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: "CollectConsignmentInfo",
  data: function data() {
    return {
      openApi: process.env.VUE_APP_OPEN_API,
      currentWidth: document.body.clientWidth,
      currentHeight: document.body.clientHeight,
      form: {
        clientName: null,
        clientMobile: null,
        clientCertificateType: "P01",
        clientCertificateNo: null,
        signature: null,
        expires: null,
        entrustType: null,
        thirdPartyUrl: null
      },
      rules: {
        clientName: [{
          required: true,
          message: '请输入姓名',
          trigger: 'blur'
        }, {
          min: 2,
          max: 50,
          pattern: _rules.default["NAME"].value,
          message: "姓名格式有误",
          trigger: ["blur", "change"]
        }],
        clientMobile: [{
          required: true,
          message: '请输入联系方式',
          trigger: 'blur'
        }, {
          pattern: _rules.default["MOBILE_RULE"].value,
          message: "联系方式格式有误",
          trigger: ["blur", "change"]
        }],
        clientCertificateType: {
          required: true,
          message: '请选择证件类型',
          trigger: 'change'
        },
        clientCertificateNo: {
          required: true,
          message: '请输入证件号码',
          trigger: 'blur'
        }
      },
      certificateTypeList: localStorage.getItem('VI_CERTIFICATE_TYPE') ? JSON.parse(localStorage.getItem('VI_CERTIFICATE_TYPE')) : [],
      loading: false,
      paramsObj: {},
      isReading: false,
      showReadBtn: false
    };
  },
  created: function created() {
    this.initData();
  },
  mounted: function mounted() {
    window.addEventListener("resize", this.resize);
    if (!this.showReadBtn) window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll: function handleScroll() {
      // 可视区域
      var clientHeight = (document.documentElement.clientHeight || window.pageYOffset || document.body.clientHeight) + 500;
      // 滚动文档高度
      var scrollHeight = document.body.scrollHeight;
      // 已滚动的高度
      var scrollTop = parseInt(document.documentElement.scrollTop || window.pageYOffset || document.body.scrollTop);
      if (scrollTop + clientHeight >= scrollHeight) {
        this.showReadBtn = true;
      }
      ;
    },
    resize: function resize() {
      if (document.body.clientHeight < document.body.clientWidth) {
        this.currentWidth = document.body.clientWidth;
        this.currentHeight = document.body.clientHeight;
      } else {
        this.currentWidth = document.body.clientWidth;
        this.currentHeight = document.body.clientHeight;
      }
      ;
    },
    initData: function initData() {
      this.paramsObj = this.$route.query.paramsObj ? JSON.parse((0, _common.base64decode)(this.$route.query.paramsObj)) : {};
      if (!_.isEmpty(this.paramsObj)) {
        this.form = {
          clientName: null,
          clientMobile: null,
          clientCertificateType: "P01",
          clientCertificateNo: null,
          signature: this.paramsObj.signature,
          expires: this.paramsObj.expires,
          entrustType: this.paramsObj.entrustType,
          thirdPartyUrl: this.paramsObj.thirdPartyUrl
        };
      }
      ;
    },
    // 判断证件类型
    certificateNoChange: function certificateNoChange() {
      if (this.form.clientCertificateType == 'P01' && this.form.clientCertificateNo) {
        var certificateNo = _rules.default['ID_CARD'].value;
        var flag = certificateNo.test(this.form.clientCertificateNo);
        var isValidate = (0, _common.idCardValidate)(this.form.clientCertificateNo);
        if (!flag || !isValidate) {
          this.$message.error('证件号码不满足校验规则，请修改！');
          return false;
        }
      }
      return true;
    },
    save: function save() {
      var _this = this;
      if (!this.certificateNoChange()) return;
      this.$refs.ruleForm.validate(function (valid) {
        if (valid) {
          _this.loading = true;
          (0, _axios.default)({
            method: "post",
            url: "".concat(_this.openApi, "/open/non-auto/ea/create"),
            data: _this.form
          }).then(function (response) {
            _this.loading = false;
            if (response.data.code == 200) {
              if (response.data.data) {
                var resData = response.data.data;
                if (resData.jumpStep == 'NON_SIGNATURE') {
                  // 待签署
                  window.location.href = resData.signatureUrl;
                } else if (resData.jumpStep == 'THIRD_PARTY') {
                  // 跳转第三方
                  window.location.href = resData.thirdPartyUrl;
                }
                ;
              }
              ;
            } else {
              _this.$message.warning(response.data.msg);
            }
            ;
          }).catch(function (error) {
            _this.loading = false;
            console.log(error, 'error');
          });
        }
      });
    }
  }
};