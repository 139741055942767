"use strict";

var _interopRequireDefault = require("/var/jenkins_home/workspace/uat-ui-web-integration/node_modules/_@babel_runtime@7.25.6@@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _axios = _interopRequireDefault(require("axios"));
var isNewVersion = function isNewVersion() {
  var url = "/version.json?t=".concat(new Date().getTime());
  _axios.default.get(url).then(function (res) {
    if (res.status === 200) {
      var vueVersion = res.data.version;
      var localVueVersion = localStorage.getItem('sysVersion');
      if (localVueVersion && localVueVersion != vueVersion) {
        localStorage.setItem('sysVersion', vueVersion);
        window.location.reload();
        return;
      } else {
        localStorage.setItem('sysVersion', vueVersion);
      }
    }
  });
};
var _default = exports.default = {
  isNewVersion: isNewVersion
};