var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { "padding-top": "8px" } },
    [
      _c(
        "el-tooltip",
        {
          attrs: { content: "更改主题色", effect: "dark", placement: "bottom" },
        },
        [
          _c("el-color-picker", {
            staticClass: "theme-picker",
            attrs: {
              predefine: [
                "#409EFF",
                "#2150A3",
                "#304156",
                "#212121",
                "#11a983",
                "#13c2c2",
                "#6959CD",
                "#f5222d",
              ],
              "popper-class": "theme-picker-dropdown",
            },
            model: {
              value: _vm.theme,
              callback: function ($$v) {
                _vm.theme = $$v
              },
              expression: "theme",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }