"use strict";

var _interopRequireDefault = require("/var/jenkins_home/workspace/uat-ui-web-integration/node_modules/_@babel_runtime@7.25.6@@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.concat.js");
var _signature_pad = _interopRequireDefault(require("signature_pad"));
var _axios = _interopRequireDefault(require("axios"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: "SignatureBoard",
  data: function data() {
    return {
      openApi: process.env.VUE_APP_OPEN_API,
      canvas: null,
      signaturePad: null,
      config: {
        minWidth: 1,
        maxWidth: 3,
        penColor: "#000"
      },
      canvasUrl: '',
      loading: false,
      contractNo: null,
      authCode: null
    };
  },
  mounted: function mounted() {
    this.init();
  },
  watch: {
    // 监听路由是否变化
    '$route': function $route(to, from) {
      if (this.signaturePad) this.resetCanvas();
      this.contractNo = this.$route.query.contractNo || null;
      this.authCode = this.$route.query.authCode || null;
    }
  },
  methods: {
    init: function init() {
      this.contractNo = this.$route.query.contractNo || null;
      this.authCode = this.$route.query.authCode || null;
      this.canvas = document.querySelector("#canvas");
      this.signaturePad = new _signature_pad.default(this.canvas, this.config);
      // this.canvas.height = document.body.clientHeight / 3;
      // this.canvas.width = document.body.clientWidth - 30;
      window.addEventListener("resize", this.resizeCanvas);
      this.resizeCanvas();

      // 读取数据，在canvas上显示
      // this.signaturePad.fromDataURL(base64);
    },
    // 清空
    resetCanvas: function resetCanvas() {
      this.signaturePad.clear();
    },
    // 转换为图片 默认为base64 png
    finishCanvas: function finishCanvas() {
      this.canvasUrl = this.signaturePad.toDataURL();
    },
    resizeCanvas: function resizeCanvas() {
      // console.log(window.devicePixelRatio);
      // let radio= Math.min(window.devicePixelRatio||1,1);
      // this.canvas.width = this.canvas.offsetWidth*radio;
      // this.canvas.height = this.canvas.offsetHeight*radio;
      if (document.body.clientHeight < document.body.clientWidth) {
        // this.canvas.height = document.body.clientHeight - 160;
        // this.canvas.width = document.body.clientWidth - 130;
        this.canvas.height = 180;
        this.canvas.width = 600;
      } else {
        this.canvas.height = 120;
        this.canvas.width = 400;
        // this.canvas.height = document.body.clientHeight - 160;
        // this.canvas.width = document.body.clientWidth - 30;
      }
      this.resetCanvas(); // otherwise isEmpty() might return incorrect value
    },
    save: function save() {
      var _this = this;
      this.loading = true;
      this.canvasUrl = this.signaturePad.toDataURL();
      var base64Content = this.canvasUrl.split('base64,')[1];
      (0, _axios.default)({
        method: "post",
        url: "".concat(this.openApi, "/open/selfsigncontract/confirmSignature?contractNo=").concat(this.contractNo, "&authCode=").concat(this.authCode),
        data: {
          file: base64Content,
          fileName: "".concat(this.contractNo, "_").concat(this.authCode, "\u7B7E\u540D\u5185\u5BB9.png")
        }
      }).then(function (response) {
        _this.loading = false;
        if (response.data.code == 200) {
          if (response.data.data) {
            var resData = response.data.data;
            if (resData.signature.singFlag) {
              window.location.href = resData.signature.returnUrl;
            }
          }
          ;
        } else {
          _this.$message.warning(response.data.msg);
        }
        ;
      }).catch(function (error) {
        _this.loading = false;
        console.log(error, 'error');
      });
    }
  }
};