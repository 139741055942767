"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var state = {
  isChildApp: false,
  loading: false,
  openDialog: false,
  appContent: ''
};
var mutations = {
  SET_IS_CHILD_APP: function SET_IS_CHILD_APP(state, status) {
    state.isChildApp = status;
  },
  SET_APP_LOADING: function SET_APP_LOADING(state, status) {
    state.loading = status;
  },
  SET_OPEN_DIALOG: function SET_OPEN_DIALOG(state, status) {
    state.openDialog = status;
  },
  SET_APP_CONTENT: function SET_APP_CONTENT(state, status) {
    // console.log('SET_APP_CONTENT=>:', status);
    state.appContent = status;
  }
};
var actions = {
  setIsChild: function setIsChild(_ref, payload) {
    var commit = _ref.commit;
    commit('SET_IS_CHILD_APP', payload);
  }
};
var _default = exports.default = {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};