var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "block" },
    [
      _c(
        "el-timeline",
        _vm._l(_vm.timeline, function (item, index) {
          return _c(
            "el-timeline-item",
            {
              key: index,
              attrs: { timestamp: item.timestamp, placement: "top" },
            },
            [
              _c("el-card", [
                _c("h4", [_vm._v(_vm._s(item.title))]),
                _c("p", [_vm._v(_vm._s(item.content))]),
              ]),
            ],
            1
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }