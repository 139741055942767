"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      timeline: [{
        timestamp: '2019/4/20',
        title: 'Update Github template',
        content: 'PanJiaChen committed 2019/4/20 20:46'
      }, {
        timestamp: '2019/4/21',
        title: 'Update Github template',
        content: 'PanJiaChen committed 2019/4/21 20:46'
      }, {
        timestamp: '2019/4/22',
        title: 'Build Template',
        content: 'PanJiaChen committed 2019/4/22 20:46'
      }, {
        timestamp: '2019/4/23',
        title: 'Release New Version',
        content: 'PanJiaChen committed 2019/4/23 20:46'
      }]
    };
  }
};