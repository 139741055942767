"use strict";

var _interopRequireDefault = require("/var/jenkins_home/workspace/uat-ui-web-integration/node_modules/_@babel_runtime@7.25.6@@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.reduce.js");
require("core-js/modules/es.object.to-string.js");
require("core-js/modules/es.regexp.exec.js");
require("core-js/modules/es.string.replace.js");
require("core-js/modules/web.dom-collections.iterator.js");
var _vue = _interopRequireDefault(require("vue"));
var _vuex = _interopRequireDefault(require("vuex"));
var _getters = _interopRequireDefault(require("./getters"));
_vue.default.use(_vuex.default);
var modulesFiles = require.context('./modules', true, /\.js$/);
var modules = modulesFiles.keys().reduce(function (modules, modulePath) {
  var moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, '$1');
  var value = modulesFiles(modulePath);
  modules[moduleName] = value.default;
  return modules;
}, {});
var store = new _vuex.default.Store({
  modules: modules,
  getters: _getters.default
});
var _default = exports.default = store;