"use strict";

var _interopRequireDefault = require("/var/jenkins_home/workspace/uat-ui-web-integration/node_modules/_@babel_runtime@7.25.6@@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.function.name.js");
var _objectSpread2 = _interopRequireDefault(require("/var/jenkins_home/workspace/uat-ui-web-integration/node_modules/_@babel_runtime@7.25.6@@babel/runtime/helpers/objectSpread2.js"));
var _RightPanel = _interopRequireDefault(require("@/components/RightPanel"));
var _components = require("./components");
var _ResizeHandler = _interopRequireDefault(require("./mixin/ResizeHandler"));
var _vuex = require("vuex");
var _qiankun = require("qiankun");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// import Dialog from '@/components/Dialog'
var _default = exports.default = {
  name: 'Layout',
  components: {
    AppMain: _components.AppMain,
    Navbar: _components.Navbar,
    RightPanel: _RightPanel.default,
    Settings: _components.Settings,
    Sidebar: _components.Sidebar,
    TagsView: _components.TagsView
    // Dialog
  },
  mixins: [_ResizeHandler.default],
  computed: (0, _objectSpread2.default)((0, _objectSpread2.default)({}, (0, _vuex.mapState)({
    sidebar: function sidebar(state) {
      return state.app.sidebar;
    },
    device: function device(state) {
      return state.app.device;
    },
    showSettings: function showSettings(state) {
      return state.settings.showSettings;
    },
    needTagsView: function needTagsView(state) {
      return state.settings.tagsView;
    },
    fixedHeader: function fixedHeader(state) {
      return state.settings.fixedHeader;
    }
  })), {}, {
    classObj: function classObj() {
      return {
        hideSidebar: !this.sidebar.opened,
        openSidebar: this.sidebar.opened,
        withoutAnimation: this.sidebar.withoutAnimation,
        mobile: this.device === 'mobile'
      };
    },
    loading: function loading() {
      return this.$store.state.workSubApp.loading;
    },
    openDialog: function openDialog() {
      var self = this;
      var _openDialog = self.$store.state.workSubApp.openDialog;
      if (_openDialog && self.appContent) {
        self.microApp = null;
        self.microApp = (0, _qiankun.loadMicroApp)({
          name: self.appContent.name,
          entry: self.appContent.name == 'sub-app2' ? 'http://localhost:9092/subapp2' : 'http://localhost:9091/subapp1',
          container: '#subContainer',
          activeRule: self.appContent.name == 'sub-app2' ? '/subapp2' : '/subapp1',
          props: {
            pushUrl: self.appContent.url
          }
        });
        setTimeout(function () {
          self.$store.commit('workSubApp/SET_APP_LOADING', false);
        }, 2000);
      }
      return this.$store.state.workSubApp.openDialog;
    },
    appContent: function appContent() {
      return this.$store.state.workSubApp.appContent;
    }
  }),
  data: function data() {
    return {
      microApp: null
    };
  },
  methods: {
    closeDialog: function closeDialog() {
      this.microApp = null;
      this.$store.commit('workSubApp/SET_APP_CONTENT', null);
      this.$store.commit('workSubApp/SET_OPEN_DIALOG', false);
    },
    handleClickOutside: function handleClickOutside() {
      this.$store.dispatch('app/closeSideBar', {
        withoutAnimation: false
      });
    }
  }
};