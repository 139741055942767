var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.contractFileUrl
    ? _c(
        "div",
        { ref: "recognitionViewRef" },
        [
          _c("PDFPreview", {
            key: "pdfView",
            ref: "pdfView",
            attrs: {
              url: _vm.contractFileUrl,
              width: _vm.recognitionViewWidth,
            },
          }),
          _c(
            "div",
            {
              staticStyle: {
                position: "fixed",
                bottom: "0",
                left: "0",
                display: "flex",
                width: "100%",
                padding: "10px",
                "box-sizing": "border-box",
                "background-color": "#fff",
              },
            },
            [
              _c(
                "el-button",
                {
                  staticStyle: { flex: "1" },
                  attrs: { round: "", type: "primary" },
                  on: { click: _vm.download },
                },
                [_vm._v("下载")]
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }