"use strict";

var _interopRequireDefault = require("/var/jenkins_home/workspace/uat-ui-web-integration/node_modules/_@babel_runtime@7.25.6@@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("driver.js/dist/driver.min.css");
var _driver = _interopRequireDefault(require("driver.js"));
var _steps = _interopRequireDefault(require("../steps"));
//
//
//
//
//
//
//
//
//
//
// import driver.js css
// import driver.js
var _default = exports.default = {
  name: 'DashboardAdmin',
  data: function data() {
    return {
      title: localStorage.getItem('title') || '',
      driver: null
    };
  },
  mounted: function mounted() {
    this.$store.commit('workSubApp/SET_APP_LOADING', false);
    var token = localStorage.getItem('token');
    if (!token) {
      // this.$store.dispatch('user/logout');
      // this.$store.dispatch('tagsView/delAllCachedViews', this.$router);
      // this.$router.push({
      //     path: '/login'
      // });
    } else {
      this.driver = new _driver.default({
        className: 'scoped-class',
        prevBtnText: '上一步',
        nextBtnText: '下一步',
        doneBtnText: '我知道了',
        closeBtnText: '关闭',
        allowClose: false
      });
      this.guide();
    }
  },
  methods: {
    guide: function guide() {
      this.driver.defineSteps(_steps.default);
      // this.driver.start();
    }
  }
};