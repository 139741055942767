var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "box" }, [
    _c(
      "div",
      { staticClass: "course_node_video" },
      [
        _c("video-player", {
          ref: "videoPlayer",
          staticClass: "video-player vjs-custom-skin",
          attrs: {
            playsinline: true,
            options: _vm.playerOptions,
            customEventName: "customstatechangedeventname",
          },
          on: {
            pause: function ($event) {
              return _vm.onPlayerPause($event)
            },
            play: _vm.onPlayerPlay,
            ended: _vm.onPlayerEnded,
            statechanged: _vm.playerStateChanged,
          },
        }),
      ],
      1
    ),
    _vm.isList
      ? _c("div", { staticClass: "videoMask" }, [
          _vm.itemPoster
            ? _c("img", {
                staticStyle: { width: "100%", height: "100%" },
                attrs: { src: _vm.itemPoster, alt: "" },
              })
            : _vm._e(),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }