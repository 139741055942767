var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "signingbox",
    },
    [
      _c(
        "div",
        { staticClass: "header" },
        [_c("el-icon", { attrs: { size: "20" } }, [_c("ArrowLeft")], 1)],
        1
      ),
      _c("div", { ref: "page", staticClass: "page" }, [
        _c("div", { staticClass: "backgrounds" }, [
          _c(
            "div",
            { staticClass: "btn_container van-hairline--top" },
            [
              _c(
                "el-button",
                {
                  staticClass: "button reSign",
                  attrs: { plain: "", size: "normal" },
                  on: { click: _vm.clearHandle },
                },
                [_vm._v("重签")]
              ),
              _vm._v(" " + _vm._s(_vm.fangxiang) + " "),
              _c(
                "el-button",
                {
                  staticClass: "button button-right",
                  attrs: { type: "primary", size: "normal" },
                  on: { click: _vm.onComfirm },
                },
                [_vm._v("提交签名")]
              ),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "canvasBox" }, [
          _c("canvas", { ref: "signHandle", staticClass: "canvas" }),
        ]),
      ]),
      !_vm.drawCheck
        ? _c(
            "div",
            {
              staticClass: "signatureBlock",
              on: {
                touchstart: function ($event) {
                  _vm.drawCheck = true
                },
              },
            },
            [_vm._v(" 签名区 ")]
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }