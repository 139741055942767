var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isReading
    ? _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "collect-consignment-info",
          style: {
            width: _vm.currentWidth + "px",
            height: _vm.currentHeight + "px",
          },
        },
        [
          _c(
            "div",
            { staticClass: "row-box" },
            [
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: 24 } }, [
                    _c("div", { staticClass: "title" }, [_vm._v("委托人信息")]),
                  ]),
                  _c(
                    "el-form",
                    {
                      ref: "ruleForm",
                      staticClass: "demo-ruleForm",
                      attrs: {
                        model: _vm.form,
                        rules: _vm.rules,
                        "label-width": "110px",
                      },
                    },
                    [
                      _c(
                        "el-col",
                        { attrs: { xs: 22, lg: 24 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "姓名：", prop: "clientName" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请输入姓名",
                                  maxlength: "40",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.form.clientName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "clientName", $$v)
                                  },
                                  expression: "form.clientName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { xs: 22, lg: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "联系方式：",
                                prop: "clientMobile",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请输入联系方式",
                                  maxlength: "11",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.form.clientMobile,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "clientMobile", $$v)
                                  },
                                  expression: "form.clientMobile",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { xs: 22, lg: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "证件类型：",
                                prop: "clientCertificateType",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    filterable: "",
                                    placeholder: "请选择证件类型",
                                    clearable: "",
                                  },
                                  model: {
                                    value: _vm.form.clientCertificateType,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.form,
                                        "clientCertificateType",
                                        $$v
                                      )
                                    },
                                    expression: "form.clientCertificateType",
                                  },
                                },
                                _vm._l(
                                  _vm.certificateTypeList,
                                  function (item, index) {
                                    return _c("el-option", {
                                      key: index,
                                      attrs: {
                                        label: item.dictName,
                                        value: item.dictCode,
                                      },
                                    })
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { xs: 22, lg: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "证件号码：",
                                prop: "clientCertificateNo",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请输入证件号码",
                                  maxlength: "40",
                                  clearable: "",
                                },
                                on: { blur: _vm.certificateNoChange },
                                model: {
                                  value: _vm.form.clientCertificateNo,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.form,
                                      "clientCertificateNo",
                                      $$v
                                    )
                                  },
                                  expression: "form.clientCertificateNo",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        {
                          staticStyle: { "margin-top": "20px" },
                          attrs: { xs: 22, lg: 24 },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "footer-btn-list" },
                            [
                              _c(
                                "el-button",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    size: "small",
                                    type: "primary",
                                    round: "",
                                  },
                                  on: { click: _vm.save },
                                },
                                [_vm._v("提交")]
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      )
    : _c("div", { staticStyle: { "padding-bottom": "52px" } }, [
        _c("img", {
          staticStyle: { width: "100%" },
          attrs: { src: require("@/assets/images/contractTemplate.png") },
        }),
        _c(
          "div",
          {
            staticStyle: {
              position: "fixed",
              bottom: "0",
              left: "0",
              display: "flex",
              width: "100%",
              padding: "10px",
              "box-sizing": "border-box",
              "background-color": "#fff",
            },
          },
          [
            _c(
              "el-button",
              {
                staticStyle: { flex: "1" },
                attrs: {
                  round: "",
                  type: "primary",
                  disabled: !_vm.showReadBtn,
                },
                on: {
                  click: function ($event) {
                    _vm.isReading = true
                  },
                },
              },
              [_vm._v("已阅读")]
            ),
          ],
          1
        ),
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }