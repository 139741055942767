var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c("van-nav-bar", {
        staticClass: "nav-top-style",
        staticStyle: { "box-sizing": "border-box" },
        style: {
          "padding-top": !_vm._.isEmpty(_vm.returnUrl) ? "40px" : "0px",
        },
        attrs: {
          fixed: "",
          title: _vm.pdfName,
          "left-arrow": !_vm._.isEmpty(_vm.returnUrl),
          "safe-area-inset-top": "",
        },
        on: { "click-left": _vm.goBack },
      }),
      _c(
        "div",
        {
          staticStyle: { width: "100%" },
          style: {
            "margin-top": !_vm._.isEmpty(_vm.returnUrl) ? "85px" : "45px",
          },
        },
        [
          _c(
            "div",
            {
              staticStyle: { "min-height": "93vh", "padding-bottom": "100px" },
            },
            [
              _vm.suffix == "pdf"
                ? _c("div", {
                    ref: _vm.pdfId,
                    staticStyle: { height: "93vh" },
                    attrs: { id: _vm.pdfId },
                  })
                : _vm._e(),
              ["png", "jpg", "jpeg", "bmp"].includes(_vm.suffix)
                ? _c(
                    "div",
                    { staticStyle: { height: "93vh", "text-align": "center" } },
                    [
                      _c("img", {
                        staticStyle: { width: "100%" },
                        attrs: { src: _vm.pdfSrc },
                      }),
                    ]
                  )
                : _vm._e(),
              !["pdf", "png", "jpg", "jpeg", "bmp"].includes(_vm.suffix)
                ? _c(
                    "div",
                    {
                      staticStyle: {
                        height: "93vh",
                        "text-align": "center",
                        "padding-top": "100px",
                      },
                    },
                    [
                      _c("svg-icon", {
                        staticClass: "car-icons",
                        staticStyle: { "font-size": "40px" },
                        attrs: { "icon-class": "failure" },
                      }),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            "line-height": "50px",
                            "font-weight": "700",
                          },
                        },
                        [_vm._v(" 当前文件不支持预览！ ")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ]
          ),
        ]
      ),
      _c("div", { staticClass: "btn-ftooer" }, [
        _c(
          "div",
          { staticClass: "btn-ftooer-s" },
          [
            _c(
              "van-button",
              {
                staticClass: "bottom-btn",
                attrs: {
                  type: "info",
                  size: "small",
                  round: "",
                  "safe-area-inset-bottom": "",
                },
                on: { click: _vm.downloadFile },
              },
              [_vm._v("复制下载链接")]
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }