"use strict";

var _interopRequireDefault = require("/var/jenkins_home/workspace/uat-ui-web-integration/node_modules/_@babel_runtime@7.25.6@@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.concat.js");
require("core-js/modules/es.array.push.js");
require("core-js/modules/es.json.stringify.js");
require("core-js/modules/es.object.keys.js");
require("core-js/modules/es.object.to-string.js");
require("core-js/modules/web.dom-collections.for-each.js");
var _layout = _interopRequireDefault(require("@/layout"));
var _AppMain = _interopRequireDefault(require("@/layout/components/AppMain"));
var _lodash = _interopRequireDefault(require("lodash"));
/** When your routing table is too long, you can split it into small modules **/

var roleData = JSON.parse(localStorage.getItem('roleData'));
var subApp = [];
if (roleData) {
  var roles = [];
  roleData.forEach(function (element, index) {
    var _children = [];
    var appCode = '';
    if (element.subMenuList) {
      element.subMenuList.forEach(function (child, index) {
        var _childs = [];
        if (child.microAppCode) appCode = child.microAppCode;
        if (child.permissionList) {
          child.permissionList.forEach(function (item) {
            // if (item.microAppCode) appCode = item.microAppCode;
            roles.push(appCode + "_" + item.microAppPermissionCode);
            // else {
            //     _childs.push({
            //         path: '/' + appCode + item.microAppMenuUrl,
            //         name: item.portalMenuId,
            //         meta: { title: item.microAppMenuName, icon: item.icon || 'el-icon-menu', keepAlive: true },
            //     })
            // }
          });
        }
        // 3级菜单
        if (!_lodash.default.isEmpty(child.subMenuList)) {
          child.subMenuList.forEach(function (per) {
            if (per.microAppCode) appCode = per.microAppCode;
            roles.push(appCode + "_" + per.microAppPermissionCode);
            _childs.push({
              path: '/' + appCode + per.microAppMenuUrl,
              name: per.portalMenuId,
              meta: {
                title: per.microAppMenuName,
                icon: per.icon || 'el-icon-menu',
                keepAlive: true
              }
            });
          });
        }
        if (element.menuType != 'B' && appCode) {
          child.microAppMenuUrl = child.microAppMenuUrl || '/' + child.portalMenuId;
          _children.push({
            component: !_lodash.default.isEmpty(_childs) ? _AppMain.default : null,
            path: '/' + appCode + child.microAppMenuUrl,
            name: child.portalMenuId,
            meta: {
              title: child.microAppMenuName,
              icon: child.icon || 'el-icon-menu',
              keepAlive: true
            },
            children: _childs
          });
        }
      });
    }
    if (appCode) {
      subApp.push({
        component: _layout.default,
        path: element.microAppMenuUrl || '/' + element.portalMenuId,
        name: element.portalMenuId,
        meta: {
          title: element.microAppMenuName,
          icon: element.icon || 'el-icon-menu',
          keepAlive: true
        },
        children: _children
      });
      //获取已渲染的左侧菜单
      var existChildren = [];
      subApp.forEach(function (app) {
        if (app.children) {
          existChildren = _lodash.default.concat(existChildren, app.children);
        }
      });
      //APP自有菜单
      var appMenus = [];
      if (localStorage.getItem(appCode)) {
        var res = JSON.parse(localStorage.getItem(appCode));
        appMenus = res || [];
      }
      if (appMenus) {
        //移除已经渲染在左侧的菜单
        existChildren.forEach(function (child) {
          _lodash.default.remove(appMenus, function (menu) {
            var _path = '/' + menu.microAppCode + menu.microAppMenuUrl;
            return child.microAppMenuUrl == _path;
          });
        });
        appMenus.forEach(function (menu) {
          subApp.push({
            component: _layout.default,
            path: '/' + menu.microAppCode + menu.microAppMenuUrl,
            name: menu.microAppMenuId,
            meta: {
              title: menu.microAppMenuName,
              icon: menu.icon || 'el-icon-menu',
              keepAlive: true
            },
            children: null,
            hidden: true
          });
        });
      }
      subApp = _lodash.default.uniqBy(subApp, 'path');
    }
  });
  if (roles) roles = _lodash.default.uniqBy(roles);
  sessionStorage.setItem('roles', JSON.stringify(roles));
}
var _default = exports.default = subApp;