var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { height: "100vh", "background-color": "#fff" } },
    [
      _vm.contractFileUrl
        ? _c(
            "div",
            { ref: "recognitionViewRef" },
            [
              _c("PDFPreview", {
                attrs: {
                  dochref: _vm.contractFileUrl,
                  pdfName: "服务函",
                  returnUrl: _vm.returnUrl,
                  pdfFileName: _vm.pdfTitle,
                },
                on: { closeViewer: _vm.closeViewer },
              }),
              _c(
                "div",
                {
                  staticStyle: {
                    position: "fixed",
                    bottom: "0",
                    left: "0",
                    display: "flex",
                    width: "100%",
                    padding: "10px",
                    "box-sizing": "border-box",
                    "background-color": "#fff",
                  },
                },
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: { flex: "1" },
                      attrs: { round: "", type: "primary" },
                      on: { click: _vm.toSign },
                    },
                    [_vm._v("去签署")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }