"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getToken = getToken;
exports.removeToken = removeToken;
exports.setToken = setToken;
function getToken() {
  return localStorage.getItem("token");
}
function setToken(token) {
  return localStorage.setItem("token", token);
}
function removeToken() {
  return localStorage.removeItem("token");
}