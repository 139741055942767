"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
/**
 * 配置编译环境和线上环境之间的切换
 * 默认三套环境，可以增添
 * 参考https://cli.vuejs.org/zh/guide/mode-and-env.html
 *
 */

// 可以多个接口域名，按需添加
var envInfo = process.env;
var _ref = [envInfo.VUE_APP_APIDOMAIN, envInfo.VUE_APP_CORE_SERVER, envInfo.VUE_APP_TP_AUTH, envInfo.VUE_APP_ORG_SERVER, envInfo.VUE_APP_STORAGE_SERVER, envInfo.VUE_APP_TOYOTA_CUSTOMIZED_SERVICES, envInfo.VUE_APP_RESOURCE_SERVER, envInfo.VUE_APP_TP_MESSAGE_SERVICE, envInfo.VUE_APP_OC_CORE_SERVER, envInfo.VUE_APP_VI_CORE_SERVER],
  apiDomain = _ref[0],
  coreServer = _ref[1],
  authServer = _ref[2],
  orgServer = _ref[3],
  tpStorageService = _ref[4],
  yfCustomizedService = _ref[5],
  resourceService = _ref[6],
  msgService = _ref[7],
  ocCoreService = _ref[8],
  viCoreServer = _ref[9];
/**
 *
 */
var _default = exports.default = {
  apiDomain: apiDomain,
  // 主域名
  coreServer: coreServer,
  //核心服务名称
  authServer: authServer,
  orgServer: orgServer,
  tpStorageService: tpStorageService,
  //存储服务
  yfCustomizedService: yfCustomizedService,
  resourceService: resourceService,
  msgService: msgService,
  ocCoreService: ocCoreService,
  viCoreServer: viCoreServer
};