var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      !_vm.isPaymented
        ? _c("van-nav-bar", {
            staticClass: "nav-top-style",
            attrs: {
              fixed: "",
              title: "服务包确认",
              "safe-area-inset-top": "",
            },
            on: { "click-left": _vm.goBack },
          })
        : _vm._e(),
      !_vm.isPaymented
        ? _c("div", [
            _c("div", { staticClass: "page-bgc information-check save-top" }, [
              _c(
                "div",
                { staticStyle: { "margin-top": "45px" } },
                [
                  _c("van-sticky", { attrs: { "offset-top": 45 } }, [
                    _c(
                      "div",
                      {
                        ref: "viewBox",
                        staticClass: "flex-center",
                        staticStyle: {
                          color: "#909090",
                          "font-size": "14px",
                          padding: "10px",
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "flex-a-center flex-j-center",
                            staticStyle: { flex: "2" },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  position: "relative",
                                  "margin-right": "5px",
                                },
                              },
                              [
                                _c("div", {
                                  staticClass: "bg-color",
                                  staticStyle: {
                                    width: "20px",
                                    height: "20px",
                                    "border-radius": "5px",
                                    transform: "rotate(45deg)",
                                  },
                                }),
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      position: "absolute",
                                      top: "2px",
                                      left: "6px",
                                      color: "#fff",
                                      "font-weight": "700",
                                    },
                                  },
                                  [_vm._v("1")]
                                ),
                              ]
                            ),
                            _c("span", { staticClass: "f-color" }, [
                              _vm._v("条款阅读"),
                            ]),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticStyle: { flex: "1", "text-align": "center" },
                          },
                          [
                            _c("svg-icon", {
                              staticStyle: { "font-size": "22px" },
                              attrs: { "icon-class": "default_right" },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "flex-a-center flex-j-center",
                            staticStyle: { flex: "2" },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  position: "relative",
                                  "margin-right": "5px",
                                },
                              },
                              [
                                _c("div", {
                                  staticClass: "bg-color",
                                  staticStyle: {
                                    width: "20px",
                                    height: "20px",
                                    "border-radius": "5px",
                                    transform: "rotate(45deg)",
                                  },
                                }),
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      position: "absolute",
                                      top: "2px",
                                      left: "6px",
                                      color: "#fff",
                                      "font-weight": "700",
                                    },
                                  },
                                  [_vm._v("2")]
                                ),
                              ]
                            ),
                            _c(
                              "span",
                              {
                                staticClass: "f-color",
                                style: {
                                  opacity: _vm.active == 1 ? "1" : ".7",
                                },
                              },
                              [_vm._v("信息校验")]
                            ),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticStyle: { flex: "1", "text-align": "center" },
                          },
                          [
                            _c("svg-icon", {
                              staticStyle: { "font-size": "22px" },
                              attrs: { "icon-class": "default_right" },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "flex-a-center flex-j-center",
                            staticStyle: { flex: "2" },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  position: "relative",
                                  "margin-right": "5px",
                                },
                              },
                              [
                                _c("div", {
                                  staticStyle: {
                                    width: "20px",
                                    height: "20px",
                                    "border-radius": "5px",
                                    transform: "rotate(45deg)",
                                  },
                                  style: {
                                    background:
                                      _vm.active == 1
                                        ? "#b0b0b0"
                                        : "linear-gradient(90deg, #ff5e1a, #ff8b20)",
                                  },
                                }),
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      position: "absolute",
                                      top: "2px",
                                      left: "6px",
                                      color: "#fff",
                                      "font-weight": "700",
                                    },
                                  },
                                  [_vm._v("3")]
                                ),
                              ]
                            ),
                            _c(
                              "span",
                              {
                                staticClass: "f-color",
                                style: {
                                  opacity: _vm.active == 1 ? ".7" : "1",
                                },
                              },
                              [_vm._v("支付费用")]
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "div",
                {
                  style: {
                    padding: "10px 15px 100px",
                    "padding-bottom":
                      _vm.orderInfo.orderStatus == "CONFIRMING"
                        ? "0px"
                        : "100px",
                  },
                },
                [
                  _vm.orderInfo.orderStatus == "CONFIRMING"
                    ? _c(
                        "div",
                        {
                          staticStyle: {
                            background: "#fff",
                            "min-height": "85vh",
                            "border-radius": "10px",
                            display: "flex",
                            "justify-content": "center",
                            "align-items": "center",
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticStyle: { "margin-top": "-250px" } },
                            [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    display: "flex",
                                    "justify-content": "center",
                                  },
                                },
                                [
                                  _c("van-loading", {
                                    attrs: { size: "20px", color: "#202c35" },
                                  }),
                                  _c(
                                    "span",
                                    { staticStyle: { "margin-left": "6px" } },
                                    [_vm._v("正在获取费用信息...")]
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm.orderInfo.orderStatus != "CONFIRMING"
                    ? _c(
                        "div",
                        {
                          staticClass: "failure-style",
                          staticStyle: {
                            "border-radius": "10px",
                            overflow: "hidden",
                          },
                        },
                        [
                          _vm.orderInfo.orderStatus == "FAILED" ||
                          _vm.orderInfo.orderStatus == "EXPIRED" ||
                          _vm.orderInfo.orderStatus == "MANUAL_APPLY" ||
                          _vm.orderInfo.orderStatus == "APPLY_MODIFY"
                            ? _c(
                                "div",
                                { staticStyle: { "margin-top": "50px" } },
                                [
                                  _c("van-cell", [
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          display: "flex",
                                          "justify-content": "center",
                                        },
                                      },
                                      [
                                        _c("svg-icon", {
                                          staticClass: "car-icons",
                                          staticStyle: { "font-size": "54px" },
                                          attrs: { "icon-class": "failure" },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          display: "flex",
                                          "justify-content": "center",
                                          "line-height": "40px",
                                          "font-weight": "700",
                                        },
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.orderInfo &&
                                                _vm.orderInfo.insurance
                                                  .insCorpName
                                                ? _vm.orderInfo.insurance
                                                    .insCorpName
                                                : ""
                                            ) +
                                              " " +
                                              _vm._s(
                                                (_vm.orderInfo &&
                                                  _vm.orderInfo
                                                    .orderStatusDisplay) ||
                                                  "核保失败"
                                              )
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ]),
                                  _c("van-cell", [
                                    _c(
                                      "div",
                                      { staticClass: "flex-a-center" },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              color: "#ff5e1a",
                                              "font-weight": "700",
                                            },
                                          },
                                          [_vm._v("重要提示：")]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "flex-a-center",
                                        staticStyle: {
                                          color: "rgba(32, 44, 53, 0.5)",
                                          "font-size": "12px",
                                        },
                                      },
                                      [
                                        _vm.orderInfo && _vm.orderInfo.errorMsg
                                          ? _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.orderInfo &&
                                                    _vm.orderInfo.errorMsg
                                                )
                                              ),
                                            ])
                                          : _vm._e(),
                                        _vm.orderInfo &&
                                        _vm.orderInfo.insurance.flowNode
                                          ? _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.orderInfo.insurance
                                                    .flowNode &&
                                                    _vm.orderInfo.insurance
                                                      .flowNode.message
                                                )
                                              ),
                                            ])
                                          : _vm._e(),
                                      ]
                                    ),
                                  ]),
                                  _vm.orderInfo.orderStatus == "MANUAL_APPLY" ||
                                  _vm.orderInfo.orderStatus == "PAYING"
                                    ? _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            "padding-top": "10px",
                                            "text-align": "center",
                                            margin: "0 20px",
                                          },
                                        },
                                        [
                                          _c(
                                            "van-button",
                                            {
                                              staticStyle: {
                                                width: "100%",
                                                height: "35px",
                                                background:
                                                  "linear-gradient(90deg, #ff5e1a, #ff8b20)",
                                                border: "0px solid #ff8b20",
                                              },
                                              attrs: {
                                                round: "",
                                                type: "warning",
                                                size: "small",
                                              },
                                              on: { click: _vm.refreshStatus },
                                            },
                                            [_vm._v("刷新投保单")]
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c("van-cell", [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "border-bgc",
                                        staticStyle: {
                                          "text-align": "left",
                                          "font-size": "12px",
                                          padding: "15px",
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              "line-height": "1.4",
                                              color: "rgba(32, 44, 53, 0.5)",
                                            },
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticStyle: {
                                                  "font-weight": "600",
                                                },
                                              },
                                              [_vm._v("温馨提示：")]
                                            ),
                                            _c("br"),
                                            _c("span", [
                                              _vm._v(
                                                "本次线上核保失败，稍后将由客服专员与您联系。"
                                              ),
                                            ]),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.orderInfo.orderStatus == "PAYMENT_WAIT" ||
                          _vm.orderInfo.orderStatus == "PAYING"
                            ? _c(
                                "div",
                                {
                                  staticStyle: {
                                    padding: "10px 0",
                                    "border-radius": "6px",
                                  },
                                },
                                [
                                  _vm.orderInfo.insurance.paymentDeadlineTime
                                    ? _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            color: "#F08C3D",
                                            "font-size": "12px",
                                            "margin-bottom": "15px",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "订单将在 " +
                                              _vm._s(
                                                _vm.orderInfo.insurance
                                                  .paymentDeadlineTime
                                              ) +
                                              " 后失效，请尽快完成支付"
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _c(
                                    "div",
                                    {
                                      staticStyle: { "margin-bottom": "20px" },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            "font-size": "20px",
                                            "font-weight": "700",
                                            "margin-bottom": "5px",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.orderInfo.insurance
                                                .coreProposalVehicleVO.plateNo
                                            )
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        { staticStyle: { color: "#909090" } },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.orderInfo.insurance
                                                .insCityName
                                            )
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "flex-center",
                                      staticStyle: {
                                        "font-size": "20px",
                                        "font-weight": "700",
                                      },
                                    },
                                    [_vm._v(_vm._s(_vm.orderInfo.servPackName))]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "flex-between",
                                      staticStyle: { margin: "20px 0 10px 0" },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "flex-a-center",
                                          staticStyle: {
                                            "padding-left": "0.2rem",
                                            "font-size": "1.2rem",
                                          },
                                        },
                                        [
                                          _vm.orderInfo.insurance.insCorpCode
                                            ? _c("img", {
                                                staticStyle: { width: "50px" },
                                                attrs: {
                                                  src: _vm.orderInfo.insurance
                                                    .insCorpSrc,
                                                },
                                              })
                                            : _vm._e(),
                                          _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                "margin-left": "0.2rem",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.orderInfo.insurance
                                                    .insCorpName
                                                )
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            "font-size": "22px",
                                            "font-weight": "700",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.orderInfo.receiptsAmount
                                                ? _vm.orderInfo.receiptsAmount.toFixed(
                                                    2
                                                  )
                                                : "0.00"
                                            ) + "元"
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _vm.orderInfo.insuranceFlag
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "border-bgc",
                                          staticStyle: {
                                            "border-top":
                                              "1px solid rgba(0,0,0, .1)",
                                            "margin-top": "10px",
                                            padding: "10px 15px",
                                            "font-size": "14px",
                                            "border-radius": "0",
                                          },
                                        },
                                        [
                                          _vm.bzRiskKind
                                            ? _c("div", [
                                                _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      margin: "10px 0",
                                                      "text-align": "left",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "flex-between",
                                                      },
                                                      [
                                                        _c("div", [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticStyle: {
                                                                "font-weight":
                                                                  "700",
                                                              },
                                                            },
                                                            [_vm._v("交强险")]
                                                          ),
                                                          _c(
                                                            "span",
                                                            {
                                                              staticStyle: {
                                                                color: "#666",
                                                                "font-size":
                                                                  "12px",
                                                                "margin-left":
                                                                  "10px",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "起保时间：" +
                                                                  _vm._s(
                                                                    _vm
                                                                      .bzRiskKind
                                                                      .effectiveStartTime
                                                                  )
                                                              ),
                                                            ]
                                                          ),
                                                        ]),
                                                        _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.bzRiskKind
                                                                .actualPremium
                                                                ? _vm.bzRiskKind.actualPremium.toFixed(
                                                                    2
                                                                  )
                                                                : "0.00"
                                                            ) + " 元"
                                                          ),
                                                        ]),
                                                      ]
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticStyle: {
                                                          margin: "10px 0",
                                                          "font-size": "12px",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " 投保单号：" +
                                                            _vm._s(
                                                              _vm.bzRiskKind &&
                                                                _vm.bzRiskKind
                                                                  .proposalNo
                                                                ? _vm.bzRiskKind
                                                                    .proposalNo
                                                                : "-"
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      margin: "10px 0",
                                                      "text-align": "left",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticStyle: {
                                                          display: "flex",
                                                          "align-items":
                                                            "center",
                                                          "justify-content":
                                                            "space-between",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticStyle: {
                                                              "font-weight":
                                                                "700",
                                                            },
                                                          },
                                                          [_vm._v("车船税")]
                                                        ),
                                                        _vm.bzRiskKind
                                                          .vehicleTaxPremium
                                                          ? _c("span", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.bzRiskKind.vehicleTaxPremium.toFixed(
                                                                    2
                                                                  )
                                                                ) + " 元"
                                                              ),
                                                            ])
                                                          : _c("span", [
                                                              _vm._v("免缴"),
                                                            ]),
                                                      ]
                                                    ),
                                                    _vm.bzRiskKind
                                                      .vehicleTaxPremium
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticStyle: {
                                                              color: "#909090",
                                                              "font-size":
                                                                "12px",
                                                              margin: "10px 0",
                                                            },
                                                          },
                                                          [
                                                            _c("span", [
                                                              _vm._v(
                                                                "当年应缴：" +
                                                                  _vm._s(
                                                                    _vm
                                                                      .bzRiskKind
                                                                      .proposalProductVehicleTaxVO
                                                                      ? _vm.bzRiskKind.proposalProductVehicleTaxVO.curYearTax.toFixed(
                                                                          2
                                                                        )
                                                                      : "0.00"
                                                                  ) +
                                                                  "元"
                                                              ),
                                                            ]),
                                                            _c(
                                                              "span",
                                                              {
                                                                staticStyle: {
                                                                  margin:
                                                                    "0 5px",
                                                                },
                                                              },
                                                              [_vm._v("|")]
                                                            ),
                                                            _c("span", [
                                                              _vm._v(
                                                                "往年欠缴：" +
                                                                  _vm._s(
                                                                    _vm
                                                                      .bzRiskKind
                                                                      .proposalProductVehicleTaxVO
                                                                      ? _vm.bzRiskKind.proposalProductVehicleTaxVO.lastYearPay.toFixed(
                                                                          2
                                                                        )
                                                                      : "0.00"
                                                                  ) +
                                                                  "元"
                                                              ),
                                                            ]),
                                                            _c(
                                                              "span",
                                                              {
                                                                staticStyle: {
                                                                  margin:
                                                                    "0 5px",
                                                                },
                                                              },
                                                              [_vm._v("|")]
                                                            ),
                                                            _c("span", [
                                                              _vm._v(
                                                                "滞纳金：" +
                                                                  _vm._s(
                                                                    _vm
                                                                      .bzRiskKind
                                                                      .proposalProductVehicleTaxVO
                                                                      ? _vm.bzRiskKind.proposalProductVehicleTaxVO.delayPayMoney.toFixed(
                                                                          2
                                                                        )
                                                                      : "0.00"
                                                                  ) +
                                                                  "元"
                                                              ),
                                                            ]),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                  ]
                                                ),
                                              ])
                                            : _vm._e(),
                                          _vm.bsRiskKind
                                            ? _c("div", [
                                                _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      margin: "10px 0",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "flex-between",
                                                      },
                                                      [
                                                        _c("div", [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticStyle: {
                                                                "font-weight":
                                                                  "700",
                                                              },
                                                            },
                                                            [_vm._v("商业险")]
                                                          ),
                                                          _c(
                                                            "span",
                                                            {
                                                              staticStyle: {
                                                                color: "#666",
                                                                "font-size":
                                                                  "12px",
                                                                "margin-left":
                                                                  "10px",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "起保时间：" +
                                                                  _vm._s(
                                                                    _vm
                                                                      .bsRiskKind
                                                                      .effectiveStartTime
                                                                  )
                                                              ),
                                                            ]
                                                          ),
                                                        ]),
                                                      ]
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticStyle: {
                                                          margin: "10px 0",
                                                          "font-size": "12px",
                                                          "text-align": "left",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " 投保单号：" +
                                                            _vm._s(
                                                              _vm.bsRiskKind &&
                                                                _vm.bsRiskKind
                                                                  .proposalNo
                                                                ? _vm.bsRiskKind
                                                                    .proposalNo
                                                                : "-"
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      color: "#909090",
                                                      "font-size": "12px",
                                                    },
                                                  },
                                                  _vm._l(
                                                    _vm.bsRiskKind
                                                      .coreProposalProductDutyVO,
                                                    function (item, index) {
                                                      return _c(
                                                        "div",
                                                        {
                                                          key: index,
                                                          staticStyle: {
                                                            margin: "10px 0",
                                                          },
                                                        },
                                                        [
                                                          _vm._.indexOf(
                                                            [
                                                              "FZ01",
                                                              "FZ02",
                                                              "FZ03",
                                                              "FZ04",
                                                            ],
                                                            item.riskkindCode
                                                          ) == -1
                                                            ? _c(
                                                                "ul",
                                                                {
                                                                  staticClass:
                                                                    "flex-between",
                                                                  staticStyle: {
                                                                    "margin-bottom":
                                                                      "0.1rem",
                                                                  },
                                                                },
                                                                [
                                                                  _c("li", [
                                                                    _c("span", [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          item.riskkindName
                                                                        )
                                                                      ),
                                                                    ]),
                                                                    _c(
                                                                      "span",
                                                                      {
                                                                        staticStyle:
                                                                          {
                                                                            margin:
                                                                              "0 5px",
                                                                          },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "|"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    item.riskkindCode ==
                                                                    "A"
                                                                      ? _c(
                                                                          "span",
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                Number(
                                                                                  item.amount
                                                                                )
                                                                                  ? item.amount
                                                                                  : _vm.AAmount()
                                                                                  ? _vm.AAmount()
                                                                                  : item.unitAmount ||
                                                                                    0
                                                                              ) +
                                                                                "  元"
                                                                            ),
                                                                          ]
                                                                        )
                                                                      : _vm._e(),
                                                                    _vm._.indexOf(
                                                                      [
                                                                        "FZ01",
                                                                        "FZ02",
                                                                        "FZ03",
                                                                        "FZ04",
                                                                        "A03",
                                                                      ],
                                                                      item.riskkindCode
                                                                    ) != -1
                                                                      ? _c(
                                                                          "span",
                                                                          [
                                                                            _vm._v(
                                                                              "投保"
                                                                            ),
                                                                          ]
                                                                        )
                                                                      : _vm._e(),
                                                                    _vm._.indexOf(
                                                                      [
                                                                        "D01",
                                                                        "B03",
                                                                        "C01",
                                                                      ],
                                                                      item.riskkindCode
                                                                    ) != -1
                                                                      ? _c(
                                                                          "span",
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                item.absoluteDeductible
                                                                                  ? item.absoluteDeductible +
                                                                                      "%"
                                                                                  : ""
                                                                              )
                                                                            ),
                                                                          ]
                                                                        )
                                                                      : _vm._e(),
                                                                    item.riskkindCode ==
                                                                    "A04"
                                                                      ? _c(
                                                                          "span",
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                (item.unitAmount >
                                                                                0
                                                                                  ? _vm.formatOption(
                                                                                      item.unitAmount
                                                                                    )
                                                                                  : "0") +
                                                                                  "元" +
                                                                                  " × " +
                                                                                  item.quantity +
                                                                                  "天"
                                                                              )
                                                                            ),
                                                                          ]
                                                                        )
                                                                      : _vm._e(),
                                                                    item.riskkindCode ==
                                                                    "A05"
                                                                      ? _c(
                                                                          "span",
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                (item.unitAmount ||
                                                                                  "0") +
                                                                                  "%"
                                                                              )
                                                                            ),
                                                                          ]
                                                                        )
                                                                      : _vm._e(),
                                                                    item.riskkindCode ==
                                                                    "A08"
                                                                      ? _c(
                                                                          "span",
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                item.unitAmount >
                                                                                  0
                                                                                  ? item.unitAmount
                                                                                  : item.amount ||
                                                                                      0
                                                                              ) +
                                                                                "元"
                                                                            ),
                                                                          ]
                                                                        )
                                                                      : _vm._e(),
                                                                    (_vm._.indexOf(
                                                                      [
                                                                        "FZ01",
                                                                        "FZ02",
                                                                        "FZ03",
                                                                        "FZ04",
                                                                        "D01",
                                                                        "B03",
                                                                        "C01",
                                                                        "A04",
                                                                        "A05",
                                                                        "A08",
                                                                        "A09",
                                                                        "B07",
                                                                        "C03",
                                                                        "D03",
                                                                        "B04",
                                                                        "A03",
                                                                      ],
                                                                      item.riskkindCode
                                                                    ) == -1 &&
                                                                      item.riskkindCode !=
                                                                        "A" &&
                                                                      item.riskkindCode !=
                                                                        "D") ||
                                                                    ([
                                                                      "C03",
                                                                      "B04",
                                                                    ].includes(
                                                                      item.riskkindCode
                                                                    ) &&
                                                                      !item.shareAmount)
                                                                      ? _c(
                                                                          "span",
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                (item.unitAmount >
                                                                                0
                                                                                  ? _vm.formatOption(
                                                                                      item.unitAmount
                                                                                    )
                                                                                  : "0") +
                                                                                  "元"
                                                                              )
                                                                            ),
                                                                          ]
                                                                        )
                                                                      : _vm._e(),
                                                                    [
                                                                      "C03",
                                                                      "D03",
                                                                      "B04",
                                                                    ].includes(
                                                                      item.riskkindCode
                                                                    ) &&
                                                                    item.shareAmount
                                                                      ? _c(
                                                                          "span",
                                                                          [
                                                                            _vm._v(
                                                                              "共享保额"
                                                                            ),
                                                                          ]
                                                                        )
                                                                      : _vm._e(),
                                                                    item.riskkindCode ==
                                                                      "D" ||
                                                                    (item.riskkindCode ==
                                                                      "D03" &&
                                                                      !item.shareAmount)
                                                                      ? _c(
                                                                          "span",
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                (item.unitAmount >
                                                                                0
                                                                                  ? _vm.formatOption(
                                                                                      item.unitAmount
                                                                                    )
                                                                                  : "0") +
                                                                                  "元" +
                                                                                  " × " +
                                                                                  item.quantity +
                                                                                  "座 "
                                                                              )
                                                                            ),
                                                                          ]
                                                                        )
                                                                      : _vm._e(),
                                                                  ]),
                                                                  _c("li", [
                                                                    _c(
                                                                      "span",
                                                                      {
                                                                        staticStyle:
                                                                          {
                                                                            "font-size":
                                                                              "12px",
                                                                            color:
                                                                              "#000",
                                                                          },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            item.actualPremium
                                                                              ? item.actualPremium.toFixed(
                                                                                  2
                                                                                )
                                                                              : "0.00"
                                                                          ) +
                                                                            " 元"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                  0
                                                ),
                                              ])
                                            : _vm._e(),
                                          !_vm._.isEmpty(_vm.nonRiskKind)
                                            ? _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    margin: "10px 0",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "flex-between",
                                                    },
                                                    [
                                                      _c("div", [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticStyle: {
                                                              "font-weight":
                                                                "700",
                                                            },
                                                          },
                                                          [_vm._v("驾意险")]
                                                        ),
                                                        _c(
                                                          "span",
                                                          {
                                                            staticStyle: {
                                                              color: "#666",
                                                              "font-size":
                                                                "12px",
                                                              "margin-left":
                                                                "10px",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "起保时间：" +
                                                                _vm._s(
                                                                  _vm
                                                                    .nonRiskKind
                                                                    .effectiveStartTime
                                                                )
                                                            ),
                                                          ]
                                                        ),
                                                      ]),
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticStyle: {
                                                        margin: "10px 0",
                                                        "font-size": "12px",
                                                        "text-align": "left",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " 投保单号：" +
                                                          _vm._s(
                                                            _vm.nonRiskKind
                                                              .proposalNo || "-"
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticStyle: {
                                                        color: "#909090",
                                                        "font-size": "12px",
                                                        "text-align": "left",
                                                      },
                                                    },
                                                    _vm._l(
                                                      _vm.nonRiskKind
                                                        .proposalRiskAccidentVO,
                                                      function (item, index) {
                                                        return _c(
                                                          "div",
                                                          { key: index },
                                                          [
                                                            _c("span", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  item.categoryName ||
                                                                    "-"
                                                                )
                                                              ),
                                                            ]),
                                                            _c("span", [
                                                              _vm._v("|"),
                                                            ]),
                                                            _c(
                                                              "span",
                                                              {
                                                                staticStyle: {
                                                                  "word-break":
                                                                    "break-all",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    item.productName +
                                                                      "(" +
                                                                      item.unitPremium +
                                                                      "元)" ||
                                                                      "-"
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        )
                                                      }
                                                    ),
                                                    0
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm.bsRiskKind &&
                                          _vm.bsRiskKind
                                            .coreProposalProductDutyVO &&
                                          _vm.bsRiskKind.coreProposalProductDutyVO.some(
                                            function (ele) {
                                              return [
                                                "FZ01",
                                                "FZ02",
                                                "FZ03",
                                                "FZ04",
                                              ].includes(ele.riskkindCode)
                                            }
                                          )
                                            ? _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    padding: "10px",
                                                    "box-sizing": "border-box",
                                                    border:
                                                      "1px solid rgba(0,0,0, .1)",
                                                    "border-radius": "5px",
                                                    display: "flex",
                                                    "flex-direction": "column",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticStyle: {
                                                        display: "flex",
                                                        "flex-direction": "row",
                                                        "justify-content":
                                                          "flex-start",
                                                        "align-items": "center",
                                                        margin: "0 0 10px 0",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticStyle: {
                                                            "font-weight":
                                                              "700",
                                                            "text-align":
                                                              "left",
                                                          },
                                                        },
                                                        [_vm._v("车险增值服务")]
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "flex-a-center",
                                                          staticStyle: {
                                                            "font-size": "12px",
                                                            color: "#808080",
                                                            "margin-left":
                                                              "5px",
                                                          },
                                                        },
                                                        [
                                                          _c("van-icon", {
                                                            staticStyle: {
                                                              "margin-right":
                                                                "2px",
                                                            },
                                                            attrs: {
                                                              name: "info-o",
                                                              size: "16",
                                                            },
                                                          }),
                                                          _c("span", [
                                                            _vm._v(
                                                              "服务由保险公司提供"
                                                            ),
                                                          ]),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "ul",
                                                    {
                                                      staticStyle: {
                                                        "font-size": "12px",
                                                      },
                                                    },
                                                    _vm._l(
                                                      _vm.bsRiskKind.coreProposalProductDutyVO.filter(
                                                        function (ele) {
                                                          return [
                                                            "FZ01",
                                                            "FZ02",
                                                            "FZ03",
                                                            "FZ04",
                                                          ].includes(
                                                            ele.riskkindCode
                                                          )
                                                        }
                                                      ),
                                                      function (item, index) {
                                                        return _c(
                                                          "li",
                                                          {
                                                            key: index,
                                                            staticStyle: {
                                                              padding: "5px 0",
                                                              "text-align":
                                                                "left",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticStyle: {
                                                                  "margin-right":
                                                                    "5px",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    item.riskkindName
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "span",
                                                              {
                                                                staticStyle: {
                                                                  "border-left":
                                                                    "1px solid rgba(0,0,0, .1)",
                                                                  "padding-left":
                                                                    "10px",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    item.quantity
                                                                  ) + "次"
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        )
                                                      }
                                                    ),
                                                    0
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]
                                      )
                                    : _vm._e(),
                                  !_vm._.isEmpty(_vm.servicePackageList)
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "flex-between",
                                          staticStyle: {
                                            "padding-top": "10px",
                                            "box-sizing": "border-box",
                                            "border-top":
                                              "1px solid rgba(0,0,0, .1)",
                                            "font-size": "16px",
                                          },
                                        },
                                        [_vm._m(0)]
                                      )
                                    : _vm._e(),
                                  !_vm._.isEmpty(_vm.servicePackageList)
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "border-bgc ",
                                          staticStyle: {
                                            "margin-top": "10px",
                                            "box-sizing": "border-box",
                                            "font-size": "14px",
                                            "line-height": "30px",
                                            "background-color":
                                              "rgb(250, 250, 250)",
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "fwbInfo" },
                                            _vm._l(
                                              _vm.servicePackageList,
                                              function (item) {
                                                return _c(
                                                  "div",
                                                  {
                                                    key: item.benefitProductCode,
                                                    staticClass: "flex-between",
                                                  },
                                                  [
                                                    _c("div", [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticStyle: {
                                                            "font-weight":
                                                              "500",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              item.productName
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                    ]),
                                                    _c("span", [
                                                      _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            item.upperLimit ||
                                                              "-"
                                                          )
                                                        ),
                                                      ]),
                                                      item.upperLimitUnitDisplay
                                                        ? _c("span", [
                                                            _vm._v(
                                                              _vm._s(
                                                                item.upperLimitUnitDisplay ||
                                                                  "-"
                                                              ) + " "
                                                            ),
                                                          ])
                                                        : _vm._e(),
                                                    ]),
                                                  ]
                                                )
                                              }
                                            ),
                                            0
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              )
                            : _vm._e(),
                        ]
                      )
                    : _vm._e(),
                ]
              ),
            ]),
            _c("div", { staticClass: "btn-ftooer bottom-bgc" }, [
              _c(
                "div",
                { staticClass: "btn-ftooer-s" },
                [
                  _vm.orderInfo.orderStatus == "PAYMENT_WAIT" ||
                  _vm.orderInfo.orderStatus == "PAYING"
                    ? _c(
                        "van-button",
                        {
                          staticClass: "bottom-btn",
                          staticStyle: {
                            "margin-right": "10px",
                            background:
                              "linear-gradient(90deg, #3981ff, #77a2f6)",
                          },
                          attrs: { type: "info", size: "small" },
                          on: {
                            click: function ($event) {
                              return _vm.toPay("ALIPAY")
                            },
                          },
                        },
                        [_vm._v("支付宝支付（推荐）")]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ]),
          ])
        : _c("div", { staticClass: "signed-page" }, [
            _c(
              "div",
              { staticClass: "rowBox" },
              [
                _c(
                  "el-row",
                  [
                    _c("el-col", { attrs: { span: 24 } }, [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            "text-align": "center",
                            "margin-bottom": "10px",
                          },
                        },
                        [
                          _c("div", {
                            staticClass: "el-icon-success",
                            staticStyle: {
                              color: "#67c23a",
                              "font-size": "50px",
                            },
                          }),
                        ]
                      ),
                      _c("div", { staticClass: "title" }, [
                        _vm._v(" 签署已完成 "),
                      ]),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
      _c(
        "van-dialog",
        {
          attrs: {
            "show-cancel-button": "",
            overlay: _vm.overlay,
            "before-close": _vm.beforeClose,
            "close-on-click-overlay": _vm.overlay,
            cancelButtonText: "关闭",
          },
          model: {
            value: _vm.showPayVerifyDialog,
            callback: function ($$v) {
              _vm.showPayVerifyDialog = $$v
            },
            expression: "showPayVerifyDialog",
          },
        },
        [
          _c(
            "div",
            { staticStyle: { background: "#fff", "text-align": "left" } },
            [
              _vm.currentflowNode && _vm.currentflowNode.message
                ? _c(
                    "div",
                    {
                      staticStyle: {
                        border: "1px solid rgba(0,0,0, .1)",
                        "border-radius": "5px",
                        padding: "10px",
                        "font-size": "13px",
                        "padding-top": "10px",
                      },
                    },
                    [
                      _c("span", { staticStyle: { color: "#ff5e1a" } }, [
                        _vm._v(_vm._s(_vm.currentflowNode.message)),
                      ]),
                    ]
                  )
                : _vm._e(),
              _c(
                "div",
                { staticStyle: { padding: "20px" } },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        "font-size": "13px",
                        "margin-bottom": "20px",
                      },
                    },
                    [
                      _c("span", [
                        _vm._v(
                          "投保人手机号：" +
                            _vm._s(
                              _vm.applicant && _vm.applicant.mobile
                                ? _vm.applicant.mobile
                                : ""
                            )
                        ),
                      ]),
                    ]
                  ),
                  _c("van-form", [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          display: "flex",
                          "align-items": "center",
                        },
                      },
                      [
                        _c("van-field", {
                          staticStyle: {
                            border: "1px solid rgba(0,0,0, .1)",
                            "border-radius": "10px",
                            "margin-right": "10px",
                          },
                          attrs: {
                            placeholder: "请输入验证码",
                            clearable: "",
                            rules: [
                              { required: true, message: "请输入验证码" },
                            ],
                          },
                          model: {
                            value: _vm.verifyForm.verifyNum,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.verifyForm,
                                "verifyNum",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "verifyForm.verifyNum",
                          },
                        }),
                        _vm.currentflowNode && _vm.currentflowNode.code
                          ? _c(
                              "div",
                              [
                                !_vm.sendMsgDisabled
                                  ? _c(
                                      "van-button",
                                      {
                                        staticStyle: { width: "90px" },
                                        attrs: {
                                          type: "info",
                                          size: "small",
                                          round: "",
                                        },
                                        on: { click: _vm.pushMsg },
                                      },
                                      [_vm._v("获取验证码")]
                                    )
                                  : _vm._e(),
                                _vm.sendMsgDisabled
                                  ? _c(
                                      "van-button",
                                      {
                                        staticStyle: { width: "100px" },
                                        attrs: {
                                          type: "default",
                                          size: "small",
                                          round: "",
                                          disabled: "",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.codeTime + "秒后获取")
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
            ]
          ),
        ]
      ),
      _c(
        "van-dialog",
        {
          attrs: {
            "show-cancel-button": "",
            overlay: _vm.overlay,
            "before-close": _vm.beforeSpecialCityClose,
            cancelButtonText: "关闭",
            "confirm-button-text": "已支付",
          },
          model: {
            value: _vm.showSpecialCity,
            callback: function ($$v) {
              _vm.showSpecialCity = $$v
            },
            expression: "showSpecialCity",
          },
        },
        [
          _c("h4", { staticStyle: { "text-align": "center" } }, [
            _vm._v(
              _vm._s(_vm.payType == "Alipay" && _vm.isWx ? "支付宝" : "微信") +
                "扫码缴费"
            ),
          ]),
          _c(
            "div",
            {
              staticClass: "flex-center",
              staticStyle: { "font-size": "12px" },
            },
            [
              _c(
                "span",
                {
                  staticStyle: {
                    color: "#202c35",
                    width: "200px",
                    "text-align": "center",
                    "margin-bottom": "20px",
                    opacity: "0.7",
                  },
                },
                [
                  _vm._v(
                    "请截图保存二维码，然后使用" +
                      _vm._s(
                        _vm.payType == "ALIPAY" && _vm.isWx ? "支付宝" : "微信"
                      ) +
                      "扫码进行认证和支付费用"
                  ),
                ]
              ),
            ]
          ),
          _c(
            "div",
            {
              staticClass: "flex-center",
              staticStyle: { "margin-bottom": "20px" },
            },
            [
              _vm.curPayUrl
                ? _c("vue-qr", {
                    attrs: {
                      text: _vm.curPayUrl,
                      margin: 0,
                      colorDark: "#000",
                      colorLight: "#fff",
                      logoScale: 0.3,
                      size: 260,
                      correctLevel: 0,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "flex-a-center", staticStyle: { "font-weight": "700" } },
      [
        _c("div", { staticStyle: { "margin-right": "0.2rem" } }, [
          _vm._v("用车服务"),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }