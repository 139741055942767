"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.push.js");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'Page404',
  computed: {
    message: function message() {
      return '该页面无法访问...';
    }
  },
  methods: {
    goHome: function goHome() {
      this.$router.push('/dashboard');
    }
  }
};