"use strict";

var _interopRequireDefault = require("/var/jenkins_home/workspace/uat-ui-web-integration/node_modules/_@babel_runtime@7.25.6@@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var _getUrl = _interopRequireDefault(require("@/utils/get-url"));
/**
 * 通用接口
 */

var objApi = {
  /**
  * 发送短信
  */
  sendCode: function sendCode(proposalId) {
    return _request.default.post((0, _getUrl.default)("/insure/sendVerifyCode?proposalId=".concat(proposalId), 16), {});
  },
  /**
   * 保存短信
   */
  saveCode: function saveCode(proposalId, data) {
    return _request.default.post((0, _getUrl.default)("/insure/saveVerifyCode?proposalId=".concat(proposalId), 16), data);
  },
  /**
   * 根据token获取用户信息
   */
  getUserByToken: function getUserByToken() {
    return _request.default.get((0, _getUrl.default)("/getUserByToken"));
  },
  /**
   * 获取当前用户的角色菜单
   */
  getUserRoleMenus: function getUserRoleMenus() {
    return _request.default.get((0, _getUrl.default)("/user/role/getUserRoleMenus/me", 1));
  },
  getOrgTreeBybizType: function getOrgTreeBybizType(bizType) {
    return _request.default.get((0, _getUrl.default)("/common/getCurrentOrganTree?bizType=".concat(bizType), 12));
  },
  /**
   * 查询门户
   * @param {*} id 当前数据的id
   */
  getPortalDetailByPortalId: function getPortalDetailByPortalId(id) {
    return _request.default.get((0, _getUrl.default)("/portal/".concat(id), 1));
  },
  /**
   * 根据域名查询门户个性化配置
   * @param {*} data 参数
   */
  queryConfigByDomain: function queryConfigByDomain(data) {
    // data = {domain:'uat-dims-plat.dfmc.com.cn'}
    return _request.default.get((0, _getUrl.default)("/portal/queryByDomain", 1), {
      params: data
    });
  },
  /**
   * 查询应用菜单
   * @param {*} microAppCode 参数
   */
  getAppMenus: function getAppMenus(microAppCode) {
    return _request.default.get((0, _getUrl.default)("/microapp/menu/getMenuListByMicroAppCode?microAppCode=".concat(microAppCode), 1));
  },
  /**
   * 获取保司下拉列表
   * @param {*} type
   */
  getAllSuppliers: function getAllSuppliers(type) {
    return _request.default.get((0, _getUrl.default)("/suppliers/getByType?type=".concat(type), 5));
  },
  /**
   * 获取省市下拉列表
   */
  getAllCityTree: function getAllCityTree() {
    return _request.default.get((0, _getUrl.default)("/districts/tree", 5));
  },
  /**
   * 获取省市区下拉列表
   */
  getAllCityAreaTree: function getAllCityAreaTree() {
    return _request.default.get((0, _getUrl.default)("/districts/tree/all", 5));
  },
  /**
   * 查询单条
   * @param {*} id 参数
   */
  getPerListByMenuId: function getPerListByMenuId(id) {
    return _request.default.get((0, _getUrl.default)("/microapp/function/get?id=".concat(id), 1));
  }
};
var _default = exports.default = objApi;