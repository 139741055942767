var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "register" }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.loading,
            expression: "loading",
          },
        ],
        staticClass: "from-information",
        attrs: {
          "element-loading-text": "正在校验中，请稍等...",
          "element-loading-spinner": "el-icon-loading",
        },
      },
      [
        _c("h1", { staticClass: "text-center" }, [_vm._v("账户申请")]),
        _c("h2", { staticClass: "text-center" }, [_vm._v(_vm._s(_vm.title))]),
        _c(
          "el-form",
          {
            ref: "form",
            attrs: {
              model: _vm.form,
              rules: _vm.rules,
              "label-width": "120px",
            },
          },
          [
            _c(
              "el-form-item",
              { attrs: { label: "经销商编码：", prop: "dealerCode" } },
              [
                _c(
                  "div",
                  { staticClass: "tip-box" },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "请输入经销商编码" },
                      model: {
                        value: _vm.form.dealerCode,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.form,
                            "dealerCode",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "form.dealerCode",
                      },
                    }),
                    _c("div", { staticClass: "tip-text" }, [
                      _vm._v("例如：1011X"),
                    ]),
                  ],
                  1
                ),
              ]
            ),
            _c(
              "el-form-item",
              { attrs: { label: "用户名：", prop: "account" } },
              [
                _c(
                  "div",
                  { staticClass: "tip-box" },
                  [
                    _c("el-input", {
                      attrs: {
                        maxlength: "32",
                        placeholder: "请输入用户名",
                        clearable: "",
                      },
                      model: {
                        value: _vm.form.account,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.form,
                            "account",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "form.account",
                      },
                    }),
                    _c(
                      "div",
                      {
                        staticClass: "tip-text",
                        staticStyle: { "line-height": "1.5" },
                      },
                      [
                        _c("div", [
                          _vm._v("请使用字母、数字、或字母和数字组合"),
                        ]),
                        _c("div", { staticStyle: { color: "red" } }, [
                          _vm._v(
                            "避免使用手机号，注册成功后，用户名不可修改!!!"
                          ),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
              ]
            ),
            _c(
              "el-form-item",
              { attrs: { label: "密码：", prop: "password" } },
              [
                _c(
                  "div",
                  { staticClass: "tip-box" },
                  [
                    _c("el-input", {
                      attrs: {
                        type: "password",
                        maxlength: "16",
                        "auto-complete": "new-password",
                        placeholder: "请输入密码",
                        clearable: "",
                        "show-password": "",
                      },
                      model: {
                        value: _vm.form.password,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.form,
                            "password",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "form.password",
                      },
                    }),
                  ],
                  1
                ),
              ]
            ),
            _c(
              "el-form-item",
              [
                _c(
                  "el-button",
                  {
                    staticClass: "apply-btn",
                    attrs: { type: "primary" },
                    on: { click: _vm.save },
                  },
                  [_vm._v("立即申请")]
                ),
                _c(
                  "el-button",
                  { staticClass: "apply-btn", on: { click: _vm.back } },
                  [_vm._v("返回")]
                ),
              ],
              1
            ),
          ],
          1
        ),
        _vm._m(0),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "password-warn" }, [
      _c("div", [
        _c("span", {
          staticClass: "el-icon-warning",
          staticStyle: { color: "#e34357" },
        }),
        _vm._v("不能包括空格 "),
      ]),
      _c("div", { staticStyle: { margin: "0 5px" } }, [
        _c("span", {
          staticClass: "el-icon-warning",
          staticStyle: { color: "#e34357" },
        }),
        _vm._v("长度为8-16个字符 "),
      ]),
      _c("div", [
        _c("span", {
          staticClass: "el-icon-warning",
          staticStyle: { color: "#e34357" },
        }),
        _vm._v("必须包含字母、数字、特殊字符中至少两种 "),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }