var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-breadcrumb",
    { staticClass: "app-breadcrumb", attrs: { separator: "|" } },
    [
      _c(
        "transition-group",
        { attrs: { name: "breadcrumb" } },
        _vm._l(_vm.topMenuTree, function (item) {
          return _c("el-breadcrumb-item", { key: item.portalTopMenuId }, [
            _c(
              "a",
              {
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.handleLink(item)
                  },
                },
              },
              [_vm._v(_vm._s(item.topMenuName))]
            ),
          ])
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }