"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = exports.default = {
  props: {
    videoOptions: {
      type: Object,
      default: function _default() {}
    },
    itemPoster: {
      type: String,
      default: ''
    },
    isList: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      currentTime: 0,
      // 秒数设置 单位默认s 进度条位置设置 ,  进入页面直接开始位置一一一一一一
      playtimes: 7,
      //  通过点击videoPosition事件改变视频位置二二二二二
      playerOptions: {}
    };
  },
  watch: {
    refresh: function refresh(newValue) {
      console.log('refresh __ newValue', newValue);
      if (newValue) {
        this.initData();
      }
      ;
    }
  },
  mounted: function mounted() {
    this.initData();
    console.log('videoOptions', this.videoOptions);
  },
  methods: {
    initData: function initData() {
      var _this = this;
      setTimeout(function () {
        _this.playerOptions = {
          playbackRates: [0.5, 1.0, 1.5, 2.0],
          // 可选的播放速度
          autoplay: false,
          // 如果为true,浏览器准备好时开始回放。
          muted: false,
          // 默认情况下将会消除任何音频。
          loop: false,
          // 是否视频一结束就重新开始。
          preload: 'auto',
          // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
          language: 'zh-CN',
          aspectRatio: '16:9',
          // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
          fluid: true,
          // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
          sources: [{
            type: "video/mp4",
            // 类型
            src: _this.videoOptions.videoUrl || '' // url地址
          }],
          videoName: '',
          // 封面标题
          briefIntroduction: '',
          // 封面简介
          thumbnailUrl: '',
          // 封面简介
          createdTime: '',
          // 创建时间
          poster: '',
          // 封面地址
          notSupportedMessage: '此视频暂无法播放，请稍后再试',
          // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
          controlBar: {
            timeDivider: true,
            // 当前时间和持续时间的分隔符
            durationDisplay: true,
            // 显示持续时间
            remainingTimeDisplay: false,
            // 是否显示剩余时间功能
            currentTimeDisplay: false,
            // 当前时间
            volumeControl: false,
            // 声音控制键
            progressControl: true,
            // 进度条
            playToggle: true,
            // 暂停-播放 按钮
            fullscreenToggle: true // 是否显示全屏按钮
          }
        };
      }, 200);
    },
    // 视频播放
    videoPlay: function videoPlay() {
      // this.player.play();
      // this.playerOptions.controlBar.playToggle = false;
      this.$refs.videoPlayer.player.play();
    },
    // 视频暂停
    videoPause: function videoPause() {
      console.log('this.$refs.videoPlayer.player', this.$refs.videoPlayer.player);
      // this.$refs.videoPlayer.player.pause();
      this.$refs.videoPlayer.player.paused();
    },
    // 视频静音
    videoMute: function videoMute() {
      this.$refs.videoPlayer.player.muted(true);
    },
    // 暂停回调   将视频播放的时间保存  暂停回调(暂停时调用)(用户操作调用)
    onPlayerPause: function onPlayerPause(player) {
      // console.log('player pause currentTime!', player.cache_.currentTime);
      // let that = this;
      this.$emit('onPlayerPause', player.cache_.currentTime);
      //   存储player.currentTime 是当前暂停时间    用于下次断续播放
    },
    // 播放回调(播放时会调用)(用户操作调用)
    onPlayerPlay: function onPlayerPlay(player) {
      console.log('player', player);
      // this.$emit('onPlayerPlay', player);
    },
    // 播放状态改变回调
    playerStateChanged: function playerStateChanged(playerCurrentState) {
      console.log('数据变化', playerCurrentState);
      // this.$emit('playerStateChanged', playerCurrentState);
    },
    //视频播完回调 (结束)(视频播放完毕调用)
    onPlayerEnded: function onPlayerEnded(player) {
      console.log('结束', player);
      // this.$emit('onPlayerEnded', player);
    }
  },
  beforeMount: function beforeMount() {
    // let id = this.$route.query.id;
    // if (id != undefined && id != null) {
    //   //编辑
    //   localStorage.setItem("videoId", id);
    //   // this.loadCourseNodeVideo(id);
    // } else {
    //   id = localStorage.getItem("videoId");
    //   if (id != undefined && id != null) {
    //     // this.loadCourseNodeVideo(id);
    //   } else {
    //     localStorage.removeItem("videoId");
    //   }
    // }
  }
};