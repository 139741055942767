var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "app-main",
    },
    [
      _c("transition", { attrs: { name: "fade-transform", mode: "out-in" } }, [
        _c(
          "div",
          [
            _c(
              "keep-alive",
              { attrs: { include: _vm.cachedViews } },
              [
                _c("router-view", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.isChildApp && _vm.$route.meta.keepAlive,
                      expression: "!isChildApp && $route.meta.keepAlive",
                    },
                  ],
                }),
              ],
              1
            ),
            _c("div", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isChildApp,
                  expression: "isChildApp",
                },
              ],
              attrs: { id: "subapp-viewport" },
            }),
          ],
          1
        ),
      ]),
      _c("transition", { attrs: { name: "fade-transform", mode: "out-in" } }, [
        _c(
          "div",
          [
            _c("router-view", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.isChildApp && !_vm.$route.meta.keepAlive,
                  expression: "!isChildApp && !$route.meta.keepAlive",
                },
              ],
            }),
            _c("div", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isChildApp,
                  expression: "isChildApp",
                },
              ],
              attrs: { id: "subapp-viewport" },
            }),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }