var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.formLoading,
          expression: "formLoading",
        },
      ],
      staticClass: "app-m-cls",
      staticStyle: { "max-height": "680px" },
    },
    [
      _c(
        "div",
        {
          ref: "header",
          staticClass: "header-cls",
          staticStyle: { "max-height": "600px", overflow: "auto" },
        },
        [
          _c(
            "el-card",
            { attrs: { shadow: "never" } },
            [
              _c("video-player", {
                ref: "videoPlayer",
                staticClass: "video-player vjs-custom-skin",
                attrs: { playsinline: true, options: _vm.playerOptions },
                on: {
                  pause: function ($event) {
                    return _vm.onPlayerPause($event)
                  },
                },
              }),
              _c("div", { staticClass: "videoDetail" }, [
                _c("div", { staticClass: "title" }, [
                  _vm._v(_vm._s(_vm.videoItem.videoName)),
                ]),
                _vm.videoItem.briefIntroduction
                  ? _c("div", { staticClass: "desc" }, [
                      _vm._v(_vm._s(_vm.videoItem.briefIntroduction)),
                    ])
                  : _vm._e(),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticStyle: {
            display: "flex",
            "justify-content": "center",
            "margin-top": "8px",
          },
        },
        [
          _c(
            "el-button",
            {
              attrs: { icon: "el-icon-close", size: "mini" },
              on: {
                click: function ($event) {
                  return _vm.closePage()
                },
              },
            },
            [_vm._v("关闭")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }