"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = exports.default = {
  props: {
    user: {
      type: Object,
      default: function _default() {
        return {
          name: '',
          email: ''
        };
      }
    }
  },
  methods: {
    submit: function submit() {
      this.$message({
        message: 'User information has been updated successfully',
        type: 'success',
        duration: 5 * 1000
      });
    }
  }
};