var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "sidebar-logo-container",
      class: { collapse: _vm.collapse },
    },
    [
      _c(
        "transition",
        { attrs: { name: "sidebarLogoFade" } },
        [
          _vm.collapse
            ? _c(
                "router-link",
                {
                  key: "collapse",
                  staticClass: "sidebar-logo-link",
                  attrs: { to: _vm.portalHomePath },
                },
                [
                  _c("h1", { staticClass: "sidebar-title" }, [
                    _vm.logo
                      ? _c("img", {
                          staticStyle: { width: "54px", height: "50px" },
                          attrs: { src: _vm.logo },
                        })
                      : _vm._e(),
                  ]),
                ]
              )
            : _c(
                "router-link",
                {
                  key: "expand",
                  staticClass: "sidebar-logo-link",
                  attrs: { to: _vm.portalHomePath },
                },
                [
                  _c("h1", { staticClass: "sidebar-title" }, [
                    !_vm.pictureUrl && _vm.logo
                      ? _c("img", {
                          staticClass: "sidebar-logo",
                          attrs: { src: _vm.logo },
                        })
                      : _vm._e(),
                    !_vm.pictureUrl
                      ? _c("span", [_vm._v(_vm._s(_vm.title))])
                      : _vm._e(),
                    _vm.pictureUrl
                      ? _c("img", {
                          staticClass: "sidebar-logo",
                          staticStyle: { width: "100%", height: "100%" },
                          attrs: { src: _vm.pictureUrl },
                        })
                      : _vm._e(),
                  ]),
                ]
              ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }