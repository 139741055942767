"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var avatarPrefix = '?imageView2/1/w/80/h/80';
var carouselPrefix = '?imageView2/2/h/440';
var _default = exports.default = {
  data: function data() {
    return {
      carouselImages: ['https://wpimg.wallstcn.com/9679ffb0-9e0b-4451-9916-e21992218054.jpg', 'https://wpimg.wallstcn.com/bcce3734-0837-4b9f-9261-351ef384f75a.jpg', 'https://wpimg.wallstcn.com/d1d7b033-d75e-4cd6-ae39-fcd5f1c0a7c5.jpg', 'https://wpimg.wallstcn.com/50530061-851b-4ca5-9dc5-2fead928a939.jpg'],
      avatarPrefix: avatarPrefix,
      carouselPrefix: carouselPrefix
    };
  }
};