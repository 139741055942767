"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = exports.default = {
  'MOBILE_RULE': {
    label: '手机号',
    value: /^((0\d{2,3}\d{7,8})|(1[3456789]\d{9}))$/
  },
  'NAME': {
    label: '名称',
    value: /^([\u4e00-\u9fa5\（\）\(\)\·]{2,40}|[a-zA-Z.\s]{2,40})$/
  },
  'LANDLINE_RULE': {
    label: '座机',
    value: /^(0\d{2,3}-[1-9]\d{6,7})$/
  },
  'ID_CARD': {
    label: '身份证号',
    value: /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/
  },
  'PASSWORD': {
    label: '密码',
    value: /(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{6,}$/
  },
  'EMAIL': {
    label: '邮箱',
    value: /^([a-zA-Z\d])(\w|\-)+@[a-zA-Z0-9_-]{2,10}(?:\.[a-zA-Z]{2,4}){1,3}$/
  },
  'DOMAIN': {
    label: '域名',
    value: /^(?=^.{3,255}$)[a-zA-Z0-9][-a-zA-Z0-9]{0,62}(\.[a-zA-Z0-9][-a-zA-Z0-9]{0,62})+$/
  },
  'URL': {
    label: '链接',
    value: /^(?=^.{3,255}$)(http(s)?:\/\/)?(www\.)?[a-zA-Z0-9][-a-zA-Z0-9]{0,62}(\.[a-zA-Z0-9][-a-zA-Z0-9]{0,62})+(:\d+)*(\/\w+\.\w+)*([\?&]\w+=\w*)*$/
  }
};