var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "sign",
    },
    [
      _c("canvas", { attrs: { id: "canvas" } }),
      _vm.signaturePad && _vm.signaturePad.isEmpty()
        ? _c("div", { staticClass: "signature-placeholder" }, [
            _vm._v("签名区"),
          ])
        : _vm._e(),
      _vm._t("default"),
      _c(
        "div",
        { staticClass: "btn" },
        [
          _c(
            "el-button",
            {
              staticStyle: { flex: "1" },
              attrs: { round: "" },
              on: { click: _vm.resetCanvas },
            },
            [_vm._v("清除")]
          ),
          _c(
            "el-button",
            {
              staticStyle: { flex: "1" },
              attrs: { round: "", type: "primary" },
              on: { click: _vm.save },
            },
            [_vm._v("提交")]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }