var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-wrapper", class: _vm.classObj },
    [
      _c("div", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.device === "mobile" && _vm.sidebar.opened,
            expression: "device==='mobile'&&sidebar.opened",
          },
        ],
        staticClass: "drawer-bg",
        on: { click: _vm.handleClickOutside },
      }),
      _c("sidebar", { staticClass: "sidebar-container" }),
      _c(
        "div",
        {
          staticClass: "main-container",
          class: { hasTagsView: _vm.needTagsView },
        },
        [
          _c(
            "div",
            { class: { "fixed-header": _vm.fixedHeader } },
            [
              _c("navbar"),
              _c("tags-view", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.needTagsView,
                    expression: "needTagsView",
                  },
                ],
              }),
            ],
            1
          ),
          _c("app-main"),
          _c(
            "right-panel",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showSettings,
                  expression: "showSettings",
                },
              ],
            },
            [_c("settings")],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }